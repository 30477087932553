import React, { useState, useEffect } from 'react';
import { RefreshCw, Wifi, WifiOff } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/card';
import Alert, { AlertDescription } from './components/ui/alert';

const MT5MarketTable = () => {
  const [symbols, setSymbols] = useState([]);
  const [marketData, setMarketData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isConnected, setIsConnected] = useState(true);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [error, setError] = useState(null);
  // Add pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  // Get settings from WordPress
  const API_TOKEN = window.mt5MarketData.apiToken;
  const ACCOUNT_ID = window.mt5MarketData.accountId;
  const SELECTED_SYMBOLS = window.mt5MarketData.selectedSymbols;

  const API_HEADERS = {
    'auth-token': API_TOKEN,
    'Content-Type': 'application/json'
  };

  // Fetch only selected symbols
  const fetchSymbols = async () => {
    try {
      // If we have selected symbols, use those instead of fetching all
      if (SELECTED_SYMBOLS && SELECTED_SYMBOLS.length > 0) {
        setSymbols(SELECTED_SYMBOLS);
        return SELECTED_SYMBOLS;
      }

      // Otherwise fetch all symbols
      const symbolsResponse = await fetch(
        `https://mt-client-api-v1.london.agiliumtrade.ai/users/current/accounts/${ACCOUNT_ID}/symbols`,
        {
          method: 'GET',
          headers: API_HEADERS
        }
      );
      
      const allSymbols = await symbolsResponse.json();
      setSymbols(allSymbols);
      return allSymbols;
    } catch (err) {
      console.error("Error fetching symbols:", err);
      setError('Failed to fetch symbols');
      return [];
    }
  };

  // Add new function to fetch stored data
  const fetchStoredData = async () => {
    try {
      console.log('📥 Fetching stored data from database...');
      const restUrl = window.mt5MarketData.restUrl || '/wp-json/';
      const response = await fetch(`${restUrl}mt5-market-data/v1/latest`);
      
      if (!response.ok) {
        console.warn('⚠️ Failed to fetch stored data');
        return;
      }

      const { data } = await response.json();
      if (data && data.length > 0) {
        console.log(`✅ Loaded ${data.length} symbols from database`);
        console.table(data.map(item => ({
          symbol: item.product,
          price: item.price,
          timestamp: item.timestamp
        })));
        
        setMarketData(data);
        setLastUpdate(new Date(data[0].timestamp));
        setIsLoading(false);
        setIsConnected(true);
        setError(null);
      } else {
        console.log('ℹ️ No stored data available');
      }
    } catch (err) {
      console.error('❌ Error fetching stored data:', err);
    }
  };

  // Initialize symbols and start market data updates
  useEffect(() => {
    const initializeData = async () => {
      // First fetch stored data
      await fetchStoredData();
      
      // Then fetch symbols and start live updates
      const initialSymbols = await fetchSymbols();
      if (initialSymbols.length > 0) {
        await fetchMarketData(initialSymbols);
      }
    };

    initializeData();
  }, []); // Run once on mount

  // Update the fetchMarketData function to merge new data with existing data
  const fetchMarketData = async (symbolsList) => {
    try {
      setIsLoading(true);
      
      const marketDataPromises = symbolsList.map(async (symbol) => {
        try {
          const [response1, response2, response3, response4, response5, response6, dailyResponse, weeklyResponse] = await Promise.all([
            fetch(
              `https://mt-client-api-v1.london.agiliumtrade.ai/users/current/accounts/${ACCOUNT_ID}/symbols/${symbol}/current-candles/1m?keepSubscription=false`,
              {
                method: 'GET',
                headers: API_HEADERS
              }
            ),
            fetch(
              `https://mt-client-api-v1.london.agiliumtrade.ai/users/current/accounts/${ACCOUNT_ID}/symbols/${symbol}/current-candles/5m?keepSubscription=false`,
              {
                method: 'GET',
                headers: API_HEADERS
              }
            ),
            fetch(
              `https://mt-client-api-v1.london.agiliumtrade.ai/users/current/accounts/${ACCOUNT_ID}/symbols/${symbol}/current-candles/15m?keepSubscription=false`,
              {
                method: 'GET',
                headers: API_HEADERS
              }
            ),
            fetch(
              `https://mt-client-api-v1.london.agiliumtrade.ai/users/current/accounts/${ACCOUNT_ID}/symbols/${symbol}/current-candles/30m?keepSubscription=false`,
              {
                method: 'GET',
                headers: API_HEADERS
              }
            ),
            fetch(
              `https://mt-client-api-v1.london.agiliumtrade.ai/users/current/accounts/${ACCOUNT_ID}/symbols/${symbol}/current-candles/1h?keepSubscription=false`,
              {
                method: 'GET',
                headers: API_HEADERS
              }
            ),
            fetch(
              `https://mt-client-api-v1.london.agiliumtrade.ai/users/current/accounts/${ACCOUNT_ID}/symbols/${symbol}/current-candles/4h?keepSubscription=false`,
              {
                method: 'GET',
                headers: API_HEADERS
              }
            ),fetch(
              `https://mt-client-api-v1.london.agiliumtrade.ai/users/current/accounts/${ACCOUNT_ID}/symbols/${symbol}/current-candles/1d?keepSubscription=false`,
              {
                method: 'GET',
                headers: API_HEADERS
              }
            ),
            fetch(
              `https://mt-client-api-v1.london.agiliumtrade.ai/users/current/accounts/${ACCOUNT_ID}/symbols/${symbol}/current-candles/1w?keepSubscription=false`,
              {
                method: 'GET',
                headers: API_HEADERS
              }
            )
          ]);

          const [response1Data, response2Data, response3Data, response4Data, response5Data, response6Data, candleData, weeklyData] = await Promise.all([
            response1.json(),
            response2.json(),
            response3.json(),
            response4.json(),
            response5.json(),
            response6.json(),
            dailyResponse.json(),
            weeklyResponse.json()
          ]);

          // Validate all numerical values
          const isValidNumber = (num) => num !== null && num !== undefined && !isNaN(num) && isFinite(num);
          
          // Check if all required numerical values are valid
          const isValidData = 
            isValidNumber(response1Data?.high) &&
            isValidNumber(response1Data?.low) &&
            isValidNumber(response1Data?.close) &&
            isValidNumber(response1Data?.open) &&
            isValidNumber(response2Data?.high) &&
            isValidNumber(response2Data?.low) &&
            isValidNumber(response2Data?.close) &&
            isValidNumber(response2Data?.open) &&
            isValidNumber(response3Data?.high) &&
            isValidNumber(response3Data?.low) &&
            isValidNumber(response3Data?.close) &&
            isValidNumber(response3Data?.open) &&
            isValidNumber(response4Data?.high) &&
            isValidNumber(response4Data?.low) &&
            isValidNumber(response4Data?.close) &&
            isValidNumber(response4Data?.open) &&
            isValidNumber(response5Data?.high) &&
            isValidNumber(response5Data?.low) &&
            isValidNumber(response5Data?.close) &&
            isValidNumber(response5Data?.open) &&
            isValidNumber(response6Data?.high) &&
            isValidNumber(response6Data?.low) &&
            isValidNumber(response6Data?.close) &&
            isValidNumber(response6Data?.open) &&
            isValidNumber(candleData?.high) &&
            isValidNumber(candleData?.low) &&
            isValidNumber(candleData?.close) &&
            isValidNumber(candleData?.open) &&
            isValidNumber(weeklyData?.close) &&
            isValidNumber(weeklyData?.open);

          if (!isValidData) {
            console.log(`Skipping update for ${symbol} - Invalid numerical data received`, {
              response1Data,
              response2Data,
              response3Data,
              response4Data,
              response5Data,
              response6Data,
              candleData,
              weeklyData
            });
            return null;
          }

          // Calculate values only if data is valid
          const marginPercentage = ((candleData.high - candleData.low) / candleData.low * 100).toFixed(2);
          const dayChange = ((candleData.close - candleData.open) / candleData.open * 100);
          const weeklyChange = ((weeklyData.close - weeklyData.open) / weeklyData.open * 100);

          // Validate calculated values
          if (!isValidNumber(marginPercentage) || !isValidNumber(dayChange) || !isValidNumber(weeklyChange)) {
            console.log(`Skipping update for ${symbol} - Invalid calculated values`, {
              marginPercentage,
              dayChange,
              weeklyChange
            });
            return null;
          }

          if (isValidData) {
            console.log(`✅ ${symbol}: Using fresh API data`);
            return {
              product: symbol,
              displayName: symbol,
              minSpread: candleData.spread && isValidNumber(candleData.spread) ? 
                candleData.spread.toFixed(5) : '-',
              margin: `${marginPercentage}%`,
              price: candleData.close,
              dayChange,
              weeklyChange,
              trend: [
                candleData.open, candleData.low, candleData.high, candleData.close,
                response6Data.open, response6Data.low, response6Data.high, response6Data.close,
                response5Data.open, response5Data.low, response5Data.high, response5Data.close,
                response4Data.open, response4Data.low, response4Data.high, response4Data.close,
                response3Data.open, response3Data.low, response3Data.high, response3Data.close,
                response2Data.open, response2Data.low, response2Data.high, response2Data.close,
                response1Data.open, response1Data.low, response1Data.high, response1Data.close
              ]
            };
          } else {
            const fallbackData = marketData.find(item => item.product === symbol);
            console.log(`⚠️ ${symbol}: Invalid API data, using fallback data from ${fallbackData?.timestamp || 'unknown time'}`);
            return fallbackData || null;
          }
        } catch (err) {
          const fallbackData = marketData.find(item => item.product === symbol);
          console.log(`❌ ${symbol}: API error, using fallback data from ${fallbackData?.timestamp || 'unknown time'}`);
          console.error(`Error details for ${symbol}:`, err);
          return fallbackData || null;
        }
      });

      const results = await Promise.all(marketDataPromises);

      // Update state while preserving last valid data
      setMarketData(prevData => {
        const newData = results.map((result, index) => {
          if (!result) {
            // If new data is null, find and return existing data for this symbol
            const symbol = symbolsList[index];
            const existingData = prevData.find(item => item.product === symbol);
            if (existingData) {
              console.log(`📦 ${symbol}: No new data available, keeping existing data from ${existingData.timestamp}`);
              return existingData;
            }
            console.log(`❓ ${symbol}: No data available at all`);
          }
          return result;
        }).filter(data => data !== null);

        // Log summary of update
        console.log('Data Update Summary:', {
          totalSymbols: symbolsList.length,
          updatedSymbols: newData.length,
          timestamp: new Date().toISOString()
        });

        // Store the new data
        storeMarketData(newData).then(success => {
          if (success) {
            console.log('💾 Successfully stored market data to database');
          } else {
            console.error('❌ Failed to store market data to database');
          }
        });
        
        return newData;
      });
      
      setLastUpdate(new Date());
      setIsConnected(true);
      setError(null);
    } catch (err) {
      console.error("❌ Error fetching market data:", err);
      setError('Failed to fetch market data');
      setIsConnected(false);
    } finally {
      setIsLoading(false);
    }
  };

  // Set up interval for market data updates
  useEffect(() => {
    if (symbols.length === 0) return;

    const interval = setInterval(() => {
      fetchMarketData(symbols);
    }, 5000); // Update every 5 seconds

    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, [symbols]); // Depend on symbols array

  // Add debug effect to monitor marketData changes
  useEffect(() => {
    console.log('Market Data Updated:', marketData);
  }, [marketData]);

  // Calculate pagination values
  const totalPages = Math.ceil(symbols.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentSymbols = symbols.slice(startIndex, endIndex);

  const renderSparkline = (trend, dayChange) => {
    if (!trend || trend.length === 0) return null;

    const width = 100;
    const height = 20;
    const padding = 2;

    // Normalize values for SVG viewport
    const min = Math.min(...trend);
    const max = Math.max(...trend);
    const range = max - min;

    const normalizeY = (value) => {
      return height - padding - ((value - min) / range) * (height - 2 * padding);
    };

    const points = trend.map((value, index) => {
      const x = (index * (width - 2 * padding)) / (trend.length - 1) + padding;
      const y = normalizeY(value);
      return `${x},${y}`;
    }).join(' ');

    const lineColor = dayChange >= 0 ? '#22c55e' : '#ef4444';

    return (
      <svg className="w-24 h-5" viewBox={`0 0 ${width} ${height}`}>
        {/* Base line */}
        <line
          x1={padding}
          y1={normalizeY(trend[0])}
          x2={width - padding}
          y2={normalizeY(trend[0])}
          stroke="#e5e7eb"
          strokeWidth="0.5"
          strokeDasharray="2,2"
        />
        {/* Trend line */}
        <polyline
          points={points}
          fill="none"
          stroke={lineColor}
          strokeWidth="1"
        />
      </svg>
    );
  };

  const renderChange = (change) => {
    if (change === null) return '-';
    const isPositive = change > 0;
    const color = isPositive ? 'text-green-500' : 'text-red-500';
    const arrow = isPositive ? '↑' : '↓';
    return (
      <span className={color}>
        {arrow} {Math.abs(change).toFixed(2)}%
      </span>
    );
  };

  const formatPrice = (price, product) => {
    if (!price) return '-';
    
    // Format based on instrument type
    if (product === 'GBP/USD') {
      return price.toFixed(4);
    } else if (product === 'XAUUSD') {
      return price.toFixed(2);
    } else if (['WTI', 'BRENT'].includes(product)) {
      return price.toFixed(2);
    } else {
      return price.toFixed(2);
    }
  };

  // Pagination controls component
  const Pagination = () => (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={() => setCurrentPage(page => Math.max(1, page - 1))}
          disabled={currentPage === 1}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
        >
          Previous
        </button>
        <button
          onClick={() => setCurrentPage(page => Math.min(totalPages, page + 1))}
          disabled={currentPage === totalPages}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{startIndex + 1}</span> to{' '}
            <span className="font-medium">{Math.min(endIndex, symbols.length)}</span> of{' '}
            <span className="font-medium">{symbols.length}</span> results
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={() => setCurrentPage(page => Math.max(1, page - 1))}
              disabled={currentPage === 1}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50"
            >
              Previous
            </button>
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                  currentPage === index + 1
                    ? 'z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                    : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => setCurrentPage(page => Math.min(totalPages, page + 1))}
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50"
            >
              Next
            </button>
          </nav>
        </div>
      </div>
    </div>
  );

  // Inside the fetchMarketData function, after setting the marketData state:
  const storeMarketData = async (newData) => {
    try {
      const restUrl = window.mt5MarketData.restUrl || '/wp-json/';
      const nonce = window.mt5MarketData.nonce;

      // Log the data being sent for debugging
      console.log('Market Data before mapping:', newData);

      const marketDataToStore = newData.map(item => {
        // Ensure trend data exists and is an array
        if (!item.trend || !Array.isArray(item.trend)) {
          console.warn(`No trend data for ${item.product}:`, item);
          return null;
        }

        // Validate trend data length
        if (item.trend.length < 28) {
          console.warn(`Incomplete trend data for ${item.product}:`, item.trend);
          return null;
        }

        return {
          product: item.product,
          price: item.price,
          minSpread: item.minSpread ? parseFloat(item.minSpread) : null,
          high: item.trend ? Math.max(...item.trend) : null,
          low: item.trend ? Math.min(...item.trend) : null,
          dayChange: item.dayChange,
          weeklyChange: item.weeklyChange,
          trend: item.trend // Send the full trend array
        };
      }).filter(Boolean); // Remove any null entries

      // Log the transformed data
      console.log('Market Data being sent to API:', marketDataToStore);

      const response = await fetch(`${restUrl}mt5-market-data/v1/store`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-WP-Nonce': nonce
        },
        body: JSON.stringify({
          marketData: marketDataToStore
        })
      });

      console.log('Store Response Status:', response.status);
      console.log('Store Response Headers:', Object.fromEntries(response.headers));

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Response Error Text:', errorText);
        
        try {
          const errorData = JSON.parse(errorText);
          console.error('Parsed Error Data:', errorData);
        } catch (e) {
          console.error('Raw Error Text:', errorText);
        }
        
        return false;
      }

      const responseData = await response.json();
      console.log('Storage successful:', responseData);
      return true;

    } catch (err) {
      console.error('Error storing market data:', err);
      return false;
    }
  };

  return (
    <Card className="w-full">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>MT5 Market Data</CardTitle>
        <div className="flex items-center gap-4 text-sm text-gray-500">
          {isConnected ? (
            <Wifi className="h-4 w-4 text-green-500" />
          ) : (
            <WifiOff className="h-4 w-4 text-red-500" />
          )}
          {lastUpdate && (
            <span>
              Last update: {lastUpdate.toLocaleTimeString()}
            </span>
          )}
          <RefreshCw 
            className={`h-4 w-4 cursor-pointer ${isLoading ? 'animate-spin' : ''}`}
            onClick={() => fetchMarketData(symbols)}
          />
        </div>
      </CardHeader>
      <CardContent>
        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b">
                <th className="text-left p-2">PRODUCT</th>
                <th className="text-right p-2">MIN SPREAD</th>
                {/* <th className="text-right p-2">MARGIN</th> */}
                <th className="text-right p-2">PRICE</th>
                <th className="text-right p-2">DAILY CHANGE</th>
                {/* <th className="text-right p-2">WEEKLY CHANGE</th> */}
                <th className="text-center p-2">TREND (24H)</th>
              </tr>
            </thead>
            <tbody>
              {symbols.length === 0 ? (
                <tr>
                  <td colSpan="7" className="text-center p-4">
                    <div className="flex items-center justify-center gap-2">
                      <RefreshCw className="h-4 w-4 animate-spin" />
                      Loading symbols...
                    </div>
                  </td>
                </tr>
              ) : (
                currentSymbols.map((symbol) => {
                  const data = marketData.find(item => item.product === symbol);
                  return (
                    <tr key={symbol} className="border-b hover:bg-gray-50">
                      <td className="p-2 font-medium">
                        {data?.displayName || symbol}
                      </td>
                      <td className="text-right p-2">
                        {data?.minSpread || '-'}
                      </td>
                      {/* <td className="text-right p-2">
                        {data?.margin || '-'}
                      </td> */}
                      <td className="text-right p-2">
                        {formatPrice(data?.price, symbol) || '-'}
                      </td>
                      <td className="text-right p-2">
                        {renderChange(data?.dayChange)}
                      </td>
                      {/* <td className="text-right p-2">
                        {renderChange(data?.weekChange)}
                      </td> */}
                      <td className="text-center p-2">
                        {data?.trend ? renderSparkline(data.trend, data.dayChange) : '-'}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <Pagination />
        <p className="text-sm text-gray-500 mt-4">
          Pricing is indicative. Past performance is not a reliable indicator of future results.
        </p>
      </CardContent>
    </Card>
  );
};

export default MT5MarketTable;
